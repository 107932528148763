import React from 'react';
import NavbarTwo from "../components/App/NavbarTwo"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import ServicesOne from "../components/Services/ServicesOne"
import MainBanner from '../components/MachineLearningAISolutions/MainBanner';


const Home = () => {
    return (
        <Layout>
            <NavbarTwo />
            <MainBanner />
            <ServicesOne />
            <Footer />
        </Layout>
    );
}

export default Home;
